<template>
  <v-container>
    <core-toolbar-name
      :page-name="
        this.$t('message.pages.title.create_function_form', {
          lang: ' ' + this.$route.params.langCode,
        })
      "
    ></core-toolbar-name>

    <v-form>
      <v-container>
        <v-row dense>
          <v-col sm="12" md="12" class="d-flex justify-start">
            <v-card elevation="0" width="100%" class="py-2">
              <v-text-field
                outlined
                :label="$t('message.labels.function-name')"
                class="text-h5"
                v-model="functionName"
                :rules="rules"
              >
                <v-icon
                  slot="prepend"
                  :color="languageColor"
                  x-large
                  class="mt-n2 mx-n1 mb-0"
                >
                  {{ languageIcon }}
                </v-icon>
              </v-text-field>
            </v-card>
          </v-col>
        </v-row>

        <v-row no-gutters class="fill-height" ref="target">
          <v-col sm="auto">
            <v-navigation-drawer dark mini-variant permanent>
              <v-sheet class="d-flex flex-column justify-start fill-height">
                <text-reader
                  v-if="functionTab"
                  @load="code = $event"
                  :langs="[languageExt]"
                ></text-reader>
                <text-reader
                  v-else
                  @load="dependencyCode = $event"
                  :langs="[dependencyExt]"
                ></text-reader>
                <v-spacer></v-spacer>
                <v-btn
                  class="mx-2 my-1"
                  fab
                  small
                  :href="`http://www.eng.it`"
                  target="_blank"
                >
                  <v-icon>mdi-help</v-icon>
                </v-btn>
                <modal-settings :handler.sync="handler" :timeout.sync="timeout">
                </modal-settings>
              </v-sheet>
            </v-navigation-drawer>
          </v-col>
          <v-col>
            <div class="primary lighten-1 d-flex flex-row-reverse" dark>
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on"
                    @click="fullscreen"
                    dark
                    class="d-none"
                  >
                    <v-icon>mdi-fullscreen</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("message.labels.fullscreen") }}</span>
              </v-tooltip>
            </div>
            <v-tabs
              v-model="tab"
              background-color="primary"
              dark
              icons-and-text
            >
              <v-tabs-slider></v-tabs-slider>
              <v-tab href="#tab-1" @change="changeLanguageExt('function')">
                app
                <v-icon>mdi-file-code-outline</v-icon>
              </v-tab>
              <v-tab href="#tab-2" @change="changeLanguageExt('dependency')">
                {{ dependencyFileName }}
                <v-icon>mdi-package-variant-closed</v-icon>
              </v-tab>
            </v-tabs>

            <v-tabs-items v-model="tab">
              <v-tab-item value="tab-1">
                <v-sheet ref="codeEditor" :height="h">
                  <monaco-editor
                    v-model="code"
                    theme="vs-dark"
                    :language="language"
                    :options="options"
                    automaticLayout="true"
                  ></monaco-editor>
                </v-sheet>
              </v-tab-item>
              <v-tab-item value="tab-2">
                <v-sheet ref="dependencyEditor" :height="h">
                  <monaco-editor
                    v-model="dependencyCode"
                    theme="vs-dark"
                    :language="dependencyLanguage"
                    :options="options"
                    automaticLayout="true"
                  ></monaco-editor>
                </v-sheet>
              </v-tab-item>
            </v-tabs-items>
          </v-col>
        </v-row>

        <v-row class="">
          <v-col cols="12" class="d-flex flex-row-reverse">
            <v-btn color="secondary" @click="createFunction()">
              {{ $t("message.btns.create") }}
            </v-btn>
            <v-btn class="mr-2" color="primary" text @click="cancelCreate()">
              {{ $t("message.btns.cancel") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-form>

    <v-card-text>
      <v-snackbar v-model="errorEnable" top color="error" right>
        "{{ error }}"
      </v-snackbar>
    </v-card-text>
  </v-container>
</template>

<script>
import CoreToolbarName from "@/components/core/ToolbarName.vue";
import ModalSettings from "@/components/ModalSettings";
import TextReader from "@/components/TextReader";
import functionFillFormMixin from "@/mixins/views/pages/FunctionsCreateForm.js";
import screenfull from "screenfull";
// The plugin @lucianodltec/monaco-editor-vue works fine only with monaco-editor-webpack-plugin v.2.1.0
import MonacoEditor from "@lucianodltec/monaco-editor-vue";

export default {
  name: "FunctionsCreateForm",
  data() {
    return {
      tab: null,
      functionTab: true,
      code: "",
      language: "",
      languageExt: "",
      languageIcon: "",
      languageColor: "",
      dependencyCode: "",
      dependencyFileName: "",
      dependencyLanguage: "",
      dependencyExt: "",
      options: {
        //Monaco Editor Options
      },
      handler: "",
      timeout: 1000,
      rules: [
        (value) => !!value || this.$t("message.error.function.name-required"),
        (value) =>
          value.length <= 20 || this.$t("message.error.function.name_lenght"),
        (value) => {
          const pattern = /^([a-z0-9]+)$/;
          return (
            pattern.test(value) ||
            this.$t("message.error.function.name-invalid")
          );
        },
      ],
      h: "450",
      w: "100%",
    };
  },
  components: {
    CoreToolbarName,
    ModalSettings,
    TextReader,
    MonacoEditor,
  },
  mixins: [functionFillFormMixin],
  methods: {
    goBack() {
      this.$router.push("/");
    },
    fullscreen() {
      if (screenfull.isEnabled) {
        screenfull.request(this.$refs.target);
        this.h = this.$refs.codeEditor.height = window.innerHeight - 120;
        this.w = this.$refs.codeEditor.width = window.innerWidth - 56;
      }
    },
    changeLanguageExt(tab) {
      if (tab != "dependency") {
        this.functionTab = true;
      } else {
        this.functionTab = false;
      }
    },
  },
};
</script>

<style scoped></style>
