import connectionMixin from "@/mixins/connectionParams.js";
import axios from 'axios'
import runtimes from '@/assets/runtimes.json'

export default {
  name: 'FunctionCreateForm',
  mixins: [connectionMixin],
  components: {
  },
  data() {
    return {
      aname: '',
      code: '',
      functionName: '',
      dependency: '',
      handler: '',
      languageObj: '',
      error: '',
      errorEnable: '',
    }
  },

  mounted() {

    this.runtimeObj = runtimes[this.$route.params.langCode];
    this.language = this.runtimeObj.language;
    this.code = this.runtimeObj.defaultCode.replace("##DEFAULTHANDLER##", this.runtimeObj.defaultHandler);
    this.functionName = 'my' + this.language + 'function'

    if (this.language != 'nodejs') { this.languageIcon = "mdi-language-" + this.language }
    else { this.languageIcon = "mdi-" + this.language }

    this.languageColor = this.runtimeObj.color;
    this.languageExt = this.runtimeObj.extension
    this.handler = this.runtimeObj.defaultHandler

    this.dependencyLanguage = this.runtimeObj.dependenciesFileLang
    this.dependencyFileName = this.runtimeObj.dependenciesFileName
    this.dependencyExt = this.runtimeObj.dependenciesFileExt
    this.dependencyCode = this.runtimeObj.dependenciesCode
  },

  methods: {

    createFunction() {
      this.error = ''
      this.items = []

      if (!this.handler) {
        this.errorEnable = true
        this.error = this.$t("message.error.function.handler_error")
        return
      }

      if (!this.functionName) {
        this.error = this.$t("message.error.function.name_error")
      }

      if (this.code) {
        axios.post(this.apihost + "/functions",
          {
            name: this.functionName,
            runtime: this.$route.params.langCode + this.runtimeObj.version,
            code: this.code,
            handler: this.functionName + '.' + this.handler,
            dependency: this.dependencyCode,
            timeout: this.timeout //in millisecond
          },
          this.opts
        ).then((response) => {
          if (Math.floor(response.status / 100) === 2) {
            this.$router.push({ name: 'Functions' })
          }
        }).catch(err => {
          this.errorHandler(err)
        })
      }
    },

    errorHandler(err) {
      this.errorEnable = true
      if (err.response.status == 400) {
        this.error = this.$t("message.error.function.create_error", { functionName: ' ' + this.functionName })
      }
      if (err.response.status == 403) {
        this.error = this.$t("message.error.function.unauthorized_error")
      }
    },

    cancelCreate() {
      this.$router.push({ name: 'Functions' })
    },

  },
}